import React, { useState } from 'react';
import ChatBot from 'react-chatbotify';
import OpenAI from 'openai';
import './sofabuddy_chatbot/styles.sass';
import makeRequest from '../lib/fetchService';
import './translations/componentsInit';
import { useTranslation } from 'react-i18next';
import ErrorMessage from './sofabuddy_chatbot/ErrorMessage';

const SofabuddyChatBot = ({ sofabuddyCookieExists }) => {
  const { t } = useTranslation();
  const apiKey = Sofatutor.Sofabuddy.openAi.apiKey;
  const openai = new OpenAI({
    apiKey: apiKey,
    dangerouslyAllowBrowser: true
  });

  let hasError = false;
  const [thread, setThread] = useState(null);
  const assistantId = 'asst_RGtBOYyGoRHTpPD7KWHNIpVo';

  const setCookie = () => {
    const apiUrl = '/sofabuddy_chat_bot_used';
    makeRequest(apiUrl, 'PATCH');
  };

  const createThread = async () => {
    if (Sofatutor.env == 'test') {
      return {
        id: 'test_thread_id'
      };
    }

    try {
      const newThread = await openai.beta.threads.create();
      setThread(newThread);
      if (Sofatutor.profileLevel) {
        await setClassLevel(newThread.id);
      }
      return newThread;
    } catch (error) {
      console.error('Error creating thread:', error);
      return null;
    }
  };

  const setClassLevel = async threadId => {
    try {
      await openai.beta.threads.messages.create(threadId, {
        role: 'assistant',
        content: `The Student you are teaching is in class level ${Sofatutor.profileLevel}. Use this information to adjust your teaching style.`
      });

      const run = await openai.beta.threads.runs.stream(threadId, {
        assistant_id: assistantId
      });

      await run.finalRun();

    } catch (error) {
      console.error('Error setting class level:', error);
    }
  };

  const sendMessage = async (params, thread) => {
    hasError = false;

    if (!sofabuddyCookieExists) {
      console.log('Setting cookie');
      setCookie();
    }

    if (Sofatutor.env == 'test') {
      await params.streamMessage('This is a test message');
      return;
    }

    console.log('Sending message:', params.userInput);
    let text = '';

    try {
      await openai.beta.threads.messages.create(thread.id, {
        role: 'user',
        content: params.userInput
      });

      const run = await openai.beta.threads.runs.stream(thread.id, {
        assistant_id: assistantId
      });

      run.on('textDelta', async delta => {
        text += delta.value || '';
        await params.streamMessage(text);
      });

      await run.finalRun();
    } catch (error) {
      await params.injectMessage(
        ErrorMessage(t('sofabuddy_chat_bot.error_message'))
      );
      hasError = true;
    }
  };

  const flow = {
    start: {
      message: sofabuddyCookieExists ? t('sofabuddy_chat_bot.return_welcome_message') : t('sofabuddy_chat_bot.first_welcome_message'),
      path: 'loop'
    },
    loop: {
      message: async params => {
        if (!thread) {
          const createdThread = await createThread();
          if (createdThread) {
            await sendMessage(params, createdThread);
          } else {
            hasError = true;
          }
        } else {
          await sendMessage(params, thread);
        }
      },
      path: () => (hasError ? 'start' : 'loop')
    }
  };

  const styles = {
    chatWindowStyle: {
      left: '1rem',
      width: '40rem',
      height: '40rem'
    },
    chatButtonStyle: {
      left: '1rem'
    }
  };

  return (
    <ChatBot
      styles={styles}
      settings={{
        header: {
          showAvatar: false,
          title: t('sofabuddy_chat_bot.title')
        },
        tooltip: {
          mode: 'NEVER'
        },
        notification: {
          disabled: true
        },
        general: {
          embedded: false,
          flowStartTrigger: 'ON_CHATBOT_INTERACT'
        },
        chatHistory: {
          storageKey: 'example_real_time_stream',
          viewChatHistoryButtonText: t('sofabuddy_chat_bot.show_chat_history')
        },
        botBubble: {
          dangerouslySetInnerHtml: true,
          simStream: Sofatutor.env == 'test' ? false : true
        },
        chatInput: {
          enabledPlaceholderText: t('sofabuddy_chat_bot.placeholder_text')
        },
        footer: {
          text: t('sofabuddy_chat_bot.mistake_hint')
        },
        fileAttachment: {
          disabled: true
        },
        emoji: {
          disabled: true
        }
      }}
      flow={flow}
    />
  );
};

export default SofabuddyChatBot;
